<template>
  <div class="license">
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
        <!-- CONTENIDO -->
        <div class="container">
            <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
            <div class="row top-buffer">
                <div class="col-md-8">
                <ol class="breadcrumb">
                    <li>
                    <a href="https://www.gob.mx"><i class="icon icon-home"></i></a>
                    </li>
                    <li><a href="https://www.gob.mx/sct">SCT</a></li>
                    <li><a href="/" >Licencia Federal Digital</a></li>
                    <li class="active">Bienvenido Conductor</li>
                </ol>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7" id="logotipo">
                <img
                    src="../assets/logo.png"
                    alt="Secretaría de Comunicaciones y Transportes"
                />
                </div>
            </div>

            <!-- TÍTULO Y SUBTÍTULO -->
            <div class="row">
                <div class="col-md-12">
                <h1>Licencia Federal Digital</h1>
                <h2>Bienvenido Conductor</h2>
                <hr class="red" />
                </div>
            </div>

            <!-- CONTENIDO -->
            <div class="row">
                <div class="col-md-4">
                <div class="form-group">
                    <label>Nombre:</label>
                    <p>
                    {{ licencia.nombre }} {{ licencia.appellido_paterno }}
                    {{ licencia.appellido_materno }}
                    </p>
                </div>
                </div>
                <div class="col-md-4">
                <div class="form-group">
                    <label>CURP:</label>
                    <p>{{ licencia.curp }}</p>
                </div>
                </div>
                <div class="col-md-4">
                <div class="form-group">
                    <label>Número de Licencia:</label>
                    <p>{{ licencia.num_licencia }}</p>
                </div>
                </div>
            </div>
            <div class="row bottom-buffer">
                <div class="col-md-4">
                <div class="form-group">
                    <label>Categoría:</label>
                    <p>{{ licencia.tipo_licencia_articulo }}</p>
                </div>
                </div>
                <div class="col-md-4">
                <div class="form-group">
                    <label>Vigencia de la Licencia:</label>
                    <p>
                    {{ licencia.inicio_vigencia | moment("DD/MM/YYYY") }} -
                    {{ licencia.fin_vigencia | moment("DD/MM/YYYY") }}
                    </p>
                </div>
                </div>
            </div>
             
            <div class="row">
                <div class="col-md-12 text-right">
                <hr />
                <button
                    class="btn btn-default"
                    type="button"
                    @click="logOut">
                    Salir
                </button>
                <a ref="btnDownload" class="btn btn-primary" type="button" download="licencia" href="data:application/pdf;base64," title='Constancia'>
                    Descargar Constancia en PDF
                </a>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import router from "../router"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "License",
  data() {
    return {
        isLoading: false,
        licencia: {},
        date:'',
    };
  },
  mounted() {
    this.licencia = JSON.parse(localStorage.getItem("usrData"));
    this.$refs.btnDownload.download= this.licencia.curp+".pdf";
    this.$refs.btnDownload.href="data:application/pdf;base64,"+this.licencia.pdf
  },
  methods: {
    logOut(){
        localStorage.clear();
        router.replace('/')
    },
  },

  components: {
    Loading
  },
};
</script>
